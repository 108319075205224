import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  align-items: center;
  justify-items: center;
  padding: 2.5rem;
  flex-direction: column;
  margin-bottom: 5rem;

  > div {
    h2 {
      font-size: 1.5rem;
      text-align: center;
    }
  }

  > h3 {
    color: #0096c7;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0.5rem;
  }

  > p {
    text-align: center;
    font-size: 0.75rem;
    margin-bottom: 1rem;
  }

  a {
    > button {
      width: 310px;
      border-radius: 7px;
      justify-content: center;
      p {
        font-weight: bold;
      }
    }
  }
`;
