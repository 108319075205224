import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  padding: 2.5rem;
  flex-direction: column;
  margin-bottom: 5rem;

  a {
    margin: 0 auto;
    img {
      width: 100px;
    }
  }

  h2 {
    margin-bottom: 3rem;
  }

  > div {
    &:last-child {
      display: flex;
      justify-content: center;
      a {
        margin: 1rem;
        img {
          max-width: 100px;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }

  @media screen and (min-width: 959px) {
    h2 {
      text-align: center;
      width: 100%;
    }
  }
`;
