import { useRoutes } from "react-router-dom";

import Home from "./Pages/Home";
import NotFound from "./Pages/NotFound";

const RouteComponent = () => {
  return useRoutes([
    { path: "*", element: <NotFound /> },
    { path: "/", element: <Home /> },
  ]);
};

export default RouteComponent;
