import AOS from "aos";

import Button from "../../Atoms/Button";
import Icon from "../../Assets/potion4.png";

import { Container } from "./style";
import { useEffect } from "react";

import "aos/dist/aos.css";

const Main = () => {
  function handleClick() {
    window.open("https://discord.gg/bZdNzScxMz", "_blank");
  }

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Container data-aos="fade-left">
      <div>
        <h1>Conhecimento nunca é demais, construa, crie e compartilhe!</h1>
        <p>
          Faça parte da nossa comunidade no discord, ter você por perto será uma
          honra.
        </p>
        <Button
          title={"Juntar-se"}
          type="tertiary"
          handleAction={() => handleClick()}
        />
      </div>

      <figure>
        <img src={Icon} alt="" />
      </figure>
    </Container>
  );
};

export default Main;
