import styled from "styled-components";

const slide = document.querySelectorAll(".swiper-slide");

export const Container = styled.section`
  width: 100%;
  padding: 2.5rem;
  max-width: 1440px;
  justify-content: space-between;
  flex-wrap: wrap;

  .swiper {
    padding: 35px;
  }

  .swiper-wrapper {
    /* justify-content: center; */
  }

  .swiper-pagination {
    bottom: -1rem;
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }

  h2 {
    font-size: 24px;
    margin-bottom: 1rem;
  }

  @media screen and (min-width: 959px) {
    .swiper-wrapper {
      justify-content: ${slide.length <= 3 && "center"};
    }

    div {
      h2 {
        margin: 0 auto;
        text-align: center;
      }
    }
  }

  @media screen and (min-width: 1160px) {
    .swiper-wrapper {
      justify-content: ${slide.length <= 4 && "center"};
    }
  }
`;
