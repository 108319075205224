import { Link } from "react-router-dom";

import Title from "../../Atoms/Title";
import Icon from "../../Assets/potion2.png";
import { Container } from "./style";

const Navbar = () => {
  return (
    <Container>
      <Link to="/">
        <Title title="Lab Zone" icon={Icon} />
      </Link>
    </Container>
  );
};

export default Navbar;
