import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  h2 {
    color: #e2e2e2;
    font-weight: bold;
  }

  img {
    margin-right: 0.5rem;
  }
`;
