import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  padding: 2.5rem;
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 5rem 0;

  div {
    width: 100%;

    h2 {
      font-size: 24px;
      margin-bottom: 1rem;
    }

    p {
      line-height: 1.5rem;
      margin-bottom: 1rem;
      text-align: justify;
      color: #9b9b9b;

      &:last-child {
        margin-bottom: 2rem;
      }
    }
  }

  figure {
    margin: 0 auto;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  @media screen and (min-width: 959px) {
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    div {
      width: initial;
      p {
        max-width: 527px;
      }
    }

    figure {
      margin: auto 2rem;
    }
  }

  @media screen and (min-width: 1100px) {
    figure {
      margin: 0 auto;
    }
  }
`;
