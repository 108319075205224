import { useEffect } from "react";
import AOS from "aos";

import { FiArrowRight } from "react-icons/fi";
import Button from "../../Atoms/Button";
import Title from "../../Atoms/Title";
import { Container } from "./style";

const Budgets = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Container data-aos="zoom-out">
      <Title title="O seu negócio ainda não está online?" />
      <h3>Precisa melhorar seus resultados?</h3>
      <p>Entre em contato clicando no botão abaixo para conversarmos</p>
      <a
        href="https://wa.me/556281428703?text=Ol%C3%A1%2C%20estou%20entrando%20em%20contato%20pelo%20seu%20website%20em%20busca%20de%20um%20or%C3%A7amento."
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button type="primary" title="QUERO UM ORÇAMENTO" icon={FiArrowRight} />
      </a>
    </Container>
  );
};

export default Budgets;
