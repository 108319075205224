import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  color: #e2e2e2;
  text-align: center;
  p {
    padding: 0 0 1rem 0;
  }
`;
