import {
  createContext,
  Dispatch,
  SetStateAction,
  ReactNode,
  useState,
} from "react";

interface ChildrenProps {
  children: ReactNode;
}

interface Context {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const initialContext: Context = {
  isOpen: false,
  setIsOpen: () => false,
};

export const IsOpenPopUpContext = createContext<Context>(initialContext);

export const IsOpenPopUpProvider = ({ children }: ChildrenProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <IsOpenPopUpContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </IsOpenPopUpContext.Provider>
  );
};
