/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState, useContext } from "react";

import { Container } from "./styles";

import sendIcon from "../../Assets/message-bro.svg";
import { IsOpenPopUpContext } from "../../Common/context/popup";

const PopUp = () => {
  const { isOpen, setIsOpen } = useContext(IsOpenPopUpContext);
  const [_seconds, setSeconds] = useState(0);

  useEffect(() => {
    if (isOpen) {
      const interval = setInterval(() => {
        setSeconds((seconds) => seconds + 1);
        setIsOpen(false);
      }, 2500);
      return () => clearInterval(interval);
    }
  }, [isOpen, setIsOpen]);

  return (
    <Container style={{ display: `${isOpen ? "flex" : "none"}` }}>
      <div>
        {isOpen}
        <img src={sendIcon} alt="Homem sentado feliz com seu notebook" />
        <p>Seu e-mail foi enviado...</p>
        <p>Obrigado por entrar em contato!</p>
      </div>
    </Container>
  );
};

export default PopUp;
