import { useEffect } from "react";
import AOS from "aos";

import team from "../../Assets/undraw_team.svg";
import Title from "../../Atoms/Title";

import { Container } from "./style";

const About = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Container data-aos="fade-right">
      <div>
        <Title title="Quem somos" />
        <p>
          A Lab Zone foi criada no intuito de engajar pessoas no ramo da
          tecnologia na troca livre de conteúdo e informação, a fim de
          solidificar a base profissional de todos a ponto de levá-lo ao mercado
          de trabalho. Vale ressaltar que está comunidade não é apenas focada em
          programação, é uma casa, um lar, então todos aqui dentro vivem em
          grande sintonia, ninguém irá te maltratar, te odiar ou até mesmo te
          julgar pelo o que você é, sinta-se livre para se expressar e mostrar o
          que há de bom em você.
        </p>

        <p>
          Para os mais engajados e fundidos a comunidade, receberá seu devido
          valor e ganhará reconhecimento aos seus feitos, então não pense que
          tempo gasto estudando e se esforçando será em vão.
        </p>

        <p>Então Go Learning 🚀</p>
      </div>

      <figure>
        <img src={team} alt={team} />
      </figure>
    </Container>
  );
};

export default About;
