import { Container } from "./styles";

export function Copyright() {

    return (
        <Container>
            <p><small>&copy; Copyright 2021 - 2024, Lab Zone - Todos os direitos reservados.</small></p>
        </Container>
    )

}
