import { useEffect } from "react";
import AOS from "aos";

import Card from "../../Atoms/Card";
import Title from "../../Atoms/Title";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-flip";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Container } from "./style";

import { Pagination, Navigation, Autoplay } from "swiper";

interface IProjects {
  image: string;
  title: string;
  url: string;
  repository: string;
}
interface IComunityProject {
  projects: IProjects[];
}

const ComunityProject = ({ projects }: IComunityProject) => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <Container data-aos="zoom-in">
      <Title title="Projetos da Comunidade" />
      <Swiper
        breakpoints={{
          360: {
            spaceBetween: 25,
          },
          414: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          600: {
            slidesPerView: 2,
          },
          830: {
            slidesPerView: 3,
          },
          1050: {
            slidesPerView: 4,
          },
          1280: {
            slidesPerView: 5,
          },
        }}
        grabCursor={true}
        pagination={true}
        navigation={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination, Navigation]}
      >
        {projects?.length ? (
          projects.map((project) => (
            <SwiperSlide>
              <Card
                image={project.image}
                title={project.title}
                url={project.url}
                repository={project.repository}
              />
            </SwiperSlide>
          ))
        ) : (
          <div
            style={{
              margin: "0 auto",
              width: "226px",
              height: "262px",
              backgroundColor: "#2f2f2f",
              borderRadius: "15px",
              boxShadow: "6px 6px 10px rgba(0, 0, 0, 0.25)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <p style={{ textAlign: "center" }}>Em Breve</p>
          </div>
        )}
      </Swiper>
    </Container>
  );
};

export default ComunityProject;
