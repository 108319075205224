import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  padding: 2.5rem;
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  flex-wrap: wrap;

  h2 {
    margin-bottom: 0.5rem;
  }

  > h3 {
    font-size: 28px;
    font-weight: bold;
    color: #0096c7;
    margin-bottom: 0.5rem;
  }

  > p {
    font-size: 16px;
    line-height: 1.2765rem;
    margin-bottom: 3rem;
  }

  > div {
    &:last-child {
      div {
        width: 100%;
      }
    }
  }

  @media screen and (min-width: 958px) {
    align-items: center;

    > p {
      max-width: 632px;
      text-align: center;
      font-size: 12px;
    }
  }

  @media screen and (min-width: 1128px) {
    > div {
      &:last-child {
        width: 100%;
        flex-wrap: wrap;
        display: flex;
        justify-content: center;

        div {
          margin: 1rem;
          flex: 0 50%;
          width: 100%;
          max-width: 491px;
        }
      }
    }
  }
`;
