import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  left: 0;

  .open {
    display: flex;
  }

  > div {
    background: #242424;
    max-width: 600px;
    width: 100%;
    height: 315px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 25px;
    margin: 0 1rem;

    p {
      text-align: center;
      font-size: 24px;
      margin-top: 1rem;

      &:last-child {
        margin-top: 0;
        font-size: 14px;
      }
    }

    img {
      width: 176px;
      height: 176px;
    }
  }

  @media screen and (max-width: 480px) {
    div {
      p {
        font-size: 18px;
        &:last-child {
          margin-top: 0;
          font-size: 12px;
        }
      }
    }
  }
`;
