import { IconType } from "react-icons";
import { FiArrowRight } from "react-icons/fi";

import { Container } from "./style";

interface ICardService {
  icon: IconType;
  title: string;
  description: string;
  url: string;
}

const CardService = ({ icon: Icon, title, description, url }: ICardService) => {
  return (
    <Container>
      <figure>
        <Icon />
      </figure>

      <h3>{title}</h3>
      <p>{description}</p>
      <a href={url} target="_blank" rel="noopener noreferrer">
        Quero fazer um orçamento <FiArrowRight size={18} />
      </a>
    </Container>
  );
};

export default CardService;
