import React, { useEffect } from "react";

interface AdSenseProps {}

declare var window: Window & {
  adsbygoogle: any[];
};

const AdSense: React.FC<AdSenseProps> = () => {
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <div>
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-4668110642225891"
        data-ad-slot="9385991422"
        data-ad-format="auto"
        data-full-width-responsive="true"
      />
    </div>
  );
};

export default AdSense;
