import React from "react";
import { BrowserRouter } from "react-router-dom";
import { IsOpenPopUpProvider } from "./Common/context/popup";
import RouteComponent from "./Routes";
import GlobalStyle from "./Styles/globalStyle";

function App() {
  return (
    <BrowserRouter>
      <IsOpenPopUpProvider>
        <RouteComponent />
        <GlobalStyle />
      </IsOpenPopUpProvider>
    </BrowserRouter>
  );
}

export default App;
