import styled from "styled-components";

export const Container = styled.div`
  width: 226px;
  height: 266px;
  background-color: #2f2f2f;
  border-radius: 15px;
  box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;

  img {
    width: 190px;
    height: 210px;
    margin: 0 auto;
    object-fit: cover;
    border-radius: 7px;
  }

  div {
    display: flex;
    justify-content: space-between;
    padding: 0 1.2rem;
    margin-top: 0.5rem;

    a {
      color: #9b9b9b;

      &:hover {
        filter: brightness(80%);
      }
    }
  }
`;
