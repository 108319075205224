import React from "react";

import { Container } from "./style";

interface ITitle {
  title: string;
  icon?: string;
}

const Title = ({ title, icon }: ITitle) => {
  return (
    <Container>
      {icon && <img src={icon} alt={icon} />}
      <h2>{title}</h2>
    </Container>
  );
};

export default Title;
