import { BsGithub } from "react-icons/bs";

import { Container } from "./style";

interface ICard {
  image: string;
  title: string;
  url: string;
  repository: string;
}

const Card = ({ image, title, url, repository }: ICard) => {
  return (
    <Container>
      <img src={image} alt={image} />
      <div>
        <a href={url} target="_blank" rel="noopener noreferrer">
          <p>{title}</p>
        </a>
        <a href={repository} target="_blank" rel="noopener noreferrer">
          <BsGithub color="#9b9b9b" />
        </a>
      </div>
    </Container>
  );
};

export default Card;
