import styled from "styled-components";

export const Container = styled.button`
  padding: 0.765rem 1rem;
  color: #ffffff;
  border-radius: 3px;
  border: none;
  font-size: 14px;
  background: ${(props) => {
    if (props.theme === "primary") return "#0096C7";
    if (props.theme === "secondary") return "#029";
    if (props.theme === "tertiary") return "#3BA55D";
  }};

  display: flex;
  align-items: center;
  
  svg {
    margin-left: 0.2rem;
  }

  &:hover {
    filter: brightness(80%);
  }
`;
