import { createElement } from "react";
import { IconType } from "react-icons";
import { Container } from "./style";

interface IButton {
  title: string;
  type: "primary" | "secondary" | "tertiary";
  icon?: IconType;
  handleAction?: () => void;
}

const Button = ({ title, type, icon, handleAction }: IButton) => {
  return (
    <Container theme={type} onClick={handleAction}>
      <p>{title}</p>
      {icon && createElement(icon, { width: 18, height: 18 })}
    </Container>
  );
};

export default Button;
