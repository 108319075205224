import AOS from "aos";
import { useEffect } from "react";

import Title from "../../Atoms/Title";
import { Container } from "./style";

const Partner = () => {
  const partners = [
    {
      name: "Zero Two",
      image_url: "/partner/zerotwo.png",
      address_url: "https://www.behance.net/02studio",
    },
  ];

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Container data-aos="zoom-in">
      <Title title="Parceiros" />

      <div>
        {partners.map((partner) => (
          <a
            href={partner.address_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={partner.image_url} alt={partner.name} />
          </a>
        ))}
      </div>
    </Container>
  );
};

export default Partner;
