import styled from "styled-components";

export const Container = styled.div`
  background-color: #2f2f2f;
  border-radius: 15px;
  padding: 2rem;
  margin-bottom: 1rem;
  height: 350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 6px 6px 10px #00000025;

  &:last-child {
    margin-bottom: 0;
  }

  figure {
    position: relative;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #9b9b9b;
    margin: 0 0 2rem 0.8rem;

    &::before {
      content: "";
      border-radius: 5px;
      position: absolute;
      left: -15px;
      top: -15px;
      background-color: #9b9b9b;
      width: 25px;
      height: 25px;
    }

    svg {
      width: 24px;
      height: 24px;
      color: #9b9b9b;
    }
  }

  h3 {
    font-weight: bold;
    color: #e2e2e2;
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
  }

  > p {
    color: #e2e2e2;
    margin-bottom: 1rem;
  }

  a {
    color: #e2e2e2;
    display: flex;
    align-items: center;

    svg {
      margin-left: 0.5rem;
    }
  }
`;
