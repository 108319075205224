import { useContext, useRef, useState } from "react";
import { MdOutlineEmail } from "react-icons/md";
import { BiPhoneCall } from "react-icons/bi";
import { SyncLoader } from "react-spinners";
import emailjs from "@emailjs/browser";

import { IsOpenPopUpContext } from "../../Common/context/popup";

import Title from "../../Atoms/Title";
import Icon from "../../Assets/potion3.png";

import { Container } from "./style";

const Footer = () => {
  const form = useRef<any>();
  const [onLoad, setOnload] = useState(false);
  const { setIsOpen } = useContext(IsOpenPopUpContext);

  const sendEmail = async (e: { preventDefault: () => void }) => {
    try {
      e.preventDefault();
      setOnload(true);
      const result = await emailjs.sendForm(
        process.env.REACT_APP_EMAIL_SERVICE_ID as string,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID as string,
        form.current,
        process.env.REACT_APP_EMAIL_PUBLIC_ID
      );

      if (!result) {
        setOnload(false);
        return alert("Ocorreu um erro, por favor tente novamente mais tarde!");
      }

      form.current[0].value = "";
      form.current[1].value = "";

      setIsOpen(true);
      return setOnload(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container>
      <div className="news-info">
        <ul className="about">
          <li>
            <Title title="Lab Zone" icon={Icon} />
          </li>
          <li>
            <p>
              “As pessoas ligam a televisão quando querem desligar o cérebro.”{" "}
            </p>
            <span>Steve Jobs</span>
          </li>
          <li>
            <a href="tel:+556281428703">
              <BiPhoneCall /> Phone: <span>+55 62 8142-8703</span>
            </a>
          </li>
          <li>
            <a href="mailto:contact.labzone@gmail.com">
              <MdOutlineEmail /> Email: <span>contact.labzone@gmail.com</span>
            </a>
          </li>
        </ul>

        <ul className="contact">
          <li>
            <h2>fale conosco</h2>
          </li>
          <li>
            <p>
              Precisando de nossos serviços, envie uma mensagem para nossa caixa
              de e-mail que iremos lhe responder.
            </p>
          </li>

          <li>
            <form ref={form} onSubmit={sendEmail} action="">
              <input
                type="text"
                name="subject"
                placeholder="Assunto"
                required
              />
              <textarea name="message" placeholder="Mensagem" required />
              <button disabled={onLoad}>
                {onLoad ? <SyncLoader size={8} color={"#3f4749"} /> : "Enviar"}
              </button>
            </form>
          </li>
        </ul>
      </div>
    </Container>
  );
};

export default Footer;
