import styled from "styled-components";

export const Container = styled.footer`
  background: var(--dark);
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5rem 0;
  border-top: ${({ theme }) => theme.border};

  .news-info {
    margin: 0 auto;
    max-width: 1280px;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-around;

    > p {
      padding: 1rem 0;
    }

    .about {
      max-width: 316px;
      list-style: none;
      li {
        color: #e2e2e2;
        a {
          color: #e2e2e2;
          display: flex;

          svg {
            margin-right: 0.5rem;
            width: 25px;
            height: 25px;
          }

          span {
            margin-left: 0.2rem;
            text-decoration: underline;
          }
        }

        &:first-child {
          display: flex;
          align-items: center;
          margin-bottom: 1rem;
          h2 {
            padding-left: 1rem;
          }
        }

        &:nth-child(2) {
          width: auto;
          margin-bottom: 0.5rem;
          span {
            filter: brightness(0.8);
          }
        }
      }
    }

    .contact {
      max-width: 316px;
      list-style: none;

      li {
        h2 {
          text-transform: uppercase;
          color: #e2e2e2;
        }

        p {
          margin-top: 0.2rem;
          color: #e2e2e2;
          margin-bottom: 1rem;
        }

        form {
          input,
          textarea {
            width: 100%;
            resize: none;
            margin-bottom: 1rem;
            border-radius: 7px;
            border: none;
            padding: 0.5rem;
            font-size: 14px;
          }

          button {
            background-color: #0096c7;
            border: none;
            padding: 0.5rem 1rem;
            border-radius: 7px;
            font-size: 14px;
            width: 80px;
            color: #ffffff;
          }
        }
      }
    }
  }

  @media screen and (max-width: 855px) {
    .background {
      bottom: 120px;
      object-fit: contain;
    }

    .news-info {
      width: 100%;

      ul {
        li {
          &:nth-child(2) {
            width: auto;
            span {
              filter: brightness(0.8);
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 631px) {
    .news-info {
      .contact {
        margin-bottom: 3rem;
      }
    }
  }
`;
