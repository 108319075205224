import AOS from "aos";

import { FiLayers } from "react-icons/fi";
import { IoRocketOutline } from "react-icons/io5";
import { RiPencilRuler2Line } from "react-icons/ri";
import { MdOutlineSpeed } from "react-icons/md";

import CardService from "../../Atoms/CardService";
import Title from "../../Atoms/Title";

import { Container } from "./style";
import { useEffect } from "react";

const Services = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const cards = [
    {
      icon: FiLayers,
      title: "Criação de sites",
      description:
        "Desenvolvemos seu site de maneira profissional, com foco em converter visitantes em vendas.",
      url: "https://wa.me/556281428703?text=Ol%C3%A1%2C%20estou%20entrando%20em%20contato%20pelo%20seu%20website%20em%20busca%20de%20um%20or%C3%A7amento.",
    },
    {
      icon: IoRocketOutline,
      title: "Criação de Landing pages",
      description:
        "Desenvolvemos seu site de maneira profissional, com foco em converter visitantes em vendas.",
      url: "https://wa.me/556281428703?text=Ol%C3%A1%2C%20estou%20entrando%20em%20contato%20pelo%20seu%20website%20em%20busca%20de%20um%20or%C3%A7amento.",
    },
    {
      icon: RiPencilRuler2Line,
      title: "Web Design",
      description:
        "Desenhamos o protótipo de todas as páginas necessárias para o seu projeto.",
      url: "https://wa.me/556281428703?text=Ol%C3%A1%2C%20estou%20entrando%20em%20contato%20pelo%20seu%20website%20em%20busca%20de%20um%20or%C3%A7amento.",
    },
    {
      icon: MdOutlineSpeed,
      title: "Otimização",
      description:
        "Otimizo toda sua estrutura de site para desempenhar em alta performance.",
      url: "https://wa.me/556281428703?text=Ol%C3%A1%2C%20estou%20entrando%20em%20contato%20pelo%20seu%20website%20em%20busca%20de%20um%20or%C3%A7amento.",
    },
  ];

  return (
    <Container data-aos="fade-right">
      <Title title="Serviços" />
      <h3>Veja como podemos ajudar o seu negócio</h3>
      <p>
        Você está pronto para aumentar suas vendas através da internet? Mais de
        150 Milhões de brasileiros já possuem acesso à internet. Então, o que
        você está esperando? Vamos lá!
      </p>

      <div>
        {cards.map((card) => (
          <CardService {...card} />
        ))}
      </div>
    </Container>
  );
};

export default Services;
