import styled from "styled-components";

export const Container = styled.main`
  width: 100%;
  padding: 2.5rem;
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  div {
    max-width: 430px;
    margin-right: 3rem;

    h1 {
      color: #e2e2e2;
      font-size: 28px;
      font-weight: 700;
    }

    > p {
      color: #9b9b9b;
      margin-bottom: 1rem;
      line-height: 1.5rem;
    }
  }

  figure {
    max-width: 50%;
    display: flex;
    justify-content: center;
    margin: 5rem auto 0 auto;
  }

  @media screen and (min-width: 540px) {
    div {
      margin-right: 3rem;
    }
  }

  @media screen and (min-width: 768px) {
    flex-direction: column;
    align-items: initial;
  }

  @media screen and (min-width: 959px) {
    flex-direction: row;
    align-items: center;

    div {
      h1 {
        font-size: 38px;
      }
    }
  }
`;
